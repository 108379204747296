import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { t } from '../../../domain/services/configService';
import { HorizontalScrollContainer } from '../../responsive/organisms/HorizontalScrollContainer';
import { SmartLink } from '../../SmartLink';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { IconPlus } from '../../Icon/IconPlus';

const styles = {
  relatedHubPages: css`
    margin: 0;
    padding: ${theme.spacing.parse('$xs $xs')};
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('s')};
    display: inline-flex;
    align-items: baseline;
    white-space: nowrap;
    text-align: center;
    li {
      list-style: none;
      display: inline-block;

      span {
        margin-left: ${theme.spacing('xs2')};
      }

      &.related-hubpages__more-link a {
        background-color: #fff;
        border-bottom: 2px solid #fc0;
      }
      a {
        display: inline-block;
        padding: ${theme.spacing.parse('$xs3 $xs2')};
        margin: ${theme.spacing.parse('0 $xs2')};
        background-color: #f0f0f0;
        color: #000;
        :hover {
          background-color: #fc0;
          svg {
            color: #000;
          }
        }
      }
    }
  `
};

const RelatedHubPagesInner = ({ hubPages, backgroundColor }) =>
  hubPages && hubPages.length === 4 ? (
    <HorizontalScrollContainer backgroundColor={backgroundColor}>
      <ul css={styles.relatedHubPages}>
        <li>
          {t('Related topics')}
          <span>:</span>
        </li>
        {hubPages.map(item => (
          <li key={item.url}>
            <SmartLink to={item.url} title={item.title}>
              {item.title}
            </SmartLink>
          </li>
        ))}
        <li className="related-hubpages__more-link">
          <SmartLink
            to={`/${t('topic')}`}
            title={t('Topics, authors and photographers')}
          >
            {`${t('__MORE__')} `}
            <IconPlus color="#fc0" size={10} />
          </SmartLink>
        </li>
      </ul>
    </HorizontalScrollContainer>
  ) : (
    <div />
  );
RelatedHubPagesInner.defaultProps = {
  backgroundColor: 'white'
};

RelatedHubPagesInner.propTypes = {
  hubPages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  backgroundColor: PropTypes.string
};

export const RelatedHubPages = ParagraphWrapper(RelatedHubPagesInner);
